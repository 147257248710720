<template>
  <div >
    <head-layout  :head-title="$t('cip.dc.QTemplate.title')" >
    </head-layout>
    <div style="height: 60px;background-color: #fff;display: flex;align-items: center;padding:0px 20px">

     <span>{{$t('cip.dc.QTemplate.field.TemplateType')}}</span>
      <span style="width:200px"><el-select v-model="Listtype" placeholder="" @change="ChangeType" >
    <el-option
      v-for="item in typeList"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select></span>
    </div>

    <el-container class="new-sino-box" v-bodyHe>

      <el-main class="new-sino-main border-box" >

        <div class="box" style="margin-top:20px">
          <div class="box-item" v-for="(item,index) in tableData" :key="index">
            <div class="box-item-content"  >
              <el-card class="box-card">
                <div class="text item" style="display: flex;justify-content: center">
                  <img src="@/assets/images/Ai/4.png" alt="">
                </div>
                <!-- <el-button>{{$t('AiSuanfa.gf')}}</el-button> -->
                <el-divider content-position="left"></el-divider>
                <div>{{item.templateName}}</div>
                <div style = 'display: flex;justify-content: center'>
                  <el-button type="text" @click="xunlianFn(item)">{{$t('cip.dc.QTemplate.field.testing')}}</el-button>
                </div>
              </el-card>
            </div>
          </div>
        </div>

      </el-main>
    </el-container>

  </div>
</template>

<script>
import {
  qualityTemplate,listTemplateType
} from "@/api/dataAcquisition/index";


  export default {

    data() {
      return {
        typeList:[],
        Listtype:"0",
        activeName: '1',
        multipleSelection:[],
        tableData: [],
        queryList:{
          "startIndex":1,
          "perPage":10,
          "algorithm_name":"",
          "algorithm_class_id":""
        },
        headBtnOptions: [{
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: ""
        }, {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: ""
        }],
        form: null,
        formLabelWidth: '120px',
        dialogFormVisible:false,
        loading:false,
        rightForm:{
          file:'',
          algorithmName: '测试',//算法名称
          algorithmClassification: '',//算法下拉
          algorithAuthor: '12',//算法描述
          algorithDescription: '123213',//算法描述
        },

      };
    },

    created() {
      this.onLoad();
      listTemplateType({}).then(res=>{
        let list = res.data.data
        this.typeList = []
        for (let key in list){
           this.typeList.push({label:list[key],value:key})
        }
         this.typeList.push({label:this.$t('cip.dc.QTemplate.field.whole'),value:"0"})
      })
    },
    methods: {
      onLoad(page, params = {}) {
        this.loading = true;
        qualityTemplate(0).then(res => {
          this.loading = false;
          let obj = res.data.data;
          this.tableData = obj
        });
      },

      ChangeType(e){
       qualityTemplate(e).then(res => {
          this.loading = false;
          let obj = res.data.data;
          this.tableData = obj
        });
      },


      xunlianFn(item){

        this.$router.push({
          path: '/Quality/testingTaskAdd',
          query: {
            type: 'xunlian',
            templateTypeId:item.id,
          }
        })
      },




    },

    mounted() {
    },
  };
</script>

<style scoped>
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
  .btn{
    margin-right: 20px;
  }
  .box{
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;
  }
  .box-item{
    width: 20%;
    padding: 0px 8px;
    box-sizing: border-box;

    margin-bottom: 10px;
  }
  .box-item-content{
   min-height:200px;

  }
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;
    /* box-shadow:10px 20px 30px 30px #ccc */
  }
  .new-sino-main .el-card.is-always-shadow{
    border:1px solid #f0f0f0!important;
  }
  .form-item{
    width: 200px;
    margin-right: 10px;
  }
  .border-box{
    border:1px solid #f0f0f0!important;
    padding:0px 20px!important;
    background-color: #fff;
  }
</style>
